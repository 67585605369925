<template>
  <v-card>
    <v-card-title>
      <span class="pl-1 primary--text">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-form
        v-model="isFormEditValid"
        ref="formEdit"
        form="formEdit"
        id="formEdit"
        @submit.prevent="saveConfig()"
      >
        <v-row>
          <v-col cols="12" sm="6">
            <!-- Origenes -->
            <v-autocomplete
              outlined
              clearable
              dense
              v-model="origenSelected"
              label="Origen"
              item-text="value"
              item-value="id"
              autocomplete="on"
              :items="origenes"
              :rules="rules.required"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <!-- Convenios -->
            <v-autocomplete
              outlined
              clearable
              dense
              v-model="convenioSelected"
              label="Convenio"
              item-text="value"
              item-value="id"
              autocomplete="on"
              :items="convenios"
              :rules="rules.required"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-autocomplete
              outlined
              dense
              clearable
              v-model="PideToken"
              :items="optionsToken"
              label="Token"
              :rules="rules.required"
              item-text="value"
              item-value="id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="1" class="pa-0 mt-2">
            <v-tooltip left max-width="25%">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  {{ infoIcon }}
                </v-icon>
              </template>
              <span>
                El sistema determinará en el siguiente orden el ingreso del
                token del prestador:
              </span>
              <ol>
                <li>Por origen y convenios</li>
                <li>Por origen y todos los convenios</li>
                <li>
                  Por convenio y todos los orígenes
                </li>
                <li>Por todos los convenios y todos los orígenes</li>
              </ol>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <!-- Fecha de vigencia desde -->
            <v-menu
              ref="menu"
              v-model="menuDesde"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaDesdeTextField"
                  label="Vigencia desde"
                  :append-icon="calendarIcon"
                  v-mask="'##/##/####'"
                  hint="Formato DD/MM/AAAA"
                  @keydown.tab="menuDesde = false"
                  onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                  @blur="[(fechaDesde = parseDateToIso(fechaDesdeTextField))]"
                  @change="[(fechaDesde = parseDateToIso(fechaDesdeTextField))]"
                  clearable
                  dense
                  outlined
                  autocomplete="off"
                  :rules="
                    rules.required.concat([
                      rules.validDateRange(fechaDesde, fechaHasta)
                    ])
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="fechaDesde"
                no-title
                scrollable
                @change="fechaDesdeTextField = formatDate(fechaDesde)"
                @input="menuDesde = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <!-- Fecha de vigencia hasta -->
            <v-menu
              ref="menu2"
              v-model="menuHasta"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaHastaTextField"
                  label="Vigencia hasta"
                  :append-icon="calendarIcon"
                  @keydown.tab="menuHasta = false"
                  v-mask="'##/##/####'"
                  onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                  hint="Formato DD/MM/AAAA"
                  @blur="[(fechaHasta = parseDateToIso(fechaHastaTextField))]"
                  @change="[(fechaHasta = parseDateToIso(fechaHastaTextField))]"
                  clearable
                  dense
                  :rules="
                    rules.required.concat([
                      rules.validDateRange(fechaDesde, fechaHasta)
                    ])
                  "
                  outlined
                  autocomplete="off"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>

              <v-date-picker
                v-model="fechaHasta"
                no-title
                scrollable
                @change="fechaHastaTextField = formatDate(fechaHasta)"
                @input="menuHasta = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn class="m-2" outlined @click="closeModal" >Cancelar</v-btn>
      <v-btn
        class="m-2"
        type="submit"
        :disabled="!isFormEditValid"
        form="formEdit"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  props: {
    tokenConfigId: {
      type: Number,
      require: true
    },
    isEdit: {
      type: Boolean,
      require: true
    }
  },
  directives: { mask },
  data: vm => ({
    infoIcon: enums.icons.SNB_INFO,
    calendarIcon: enums.icons.CALENDAR,
    fechaDesde: null,
    fechaDesdeTextField: null,
    menuDesde: false,
    fechaHasta: null,
    fechaHastaTextField: null,
    menuHasta: false,
    rules: rules,
    isFormEditValid: false,
    formTitle: "Editar configuración",
    convenioSelected: null,
    convenios: [],
    origenSelected: null,
    origenes: [],
    ejemplo: null,
    PideToken: null,
    optionsToken: [],
  }),
  created() {
    if (this.tokenConfigId != null) {
      this.setConfig();
    } else {
      this.formTitle = "Nueva configuración";
    }
    this.loadParameters();
  },
  methods: {
    ...mapActions({
      fetchConvenios: "configAppBenef/fetchConvenios",
      fetchOrigenes: "configAppBenef/fetchOrigenes",
      getTokenConfigById: "configAppBenef/getTokenConfigById",
      fetchPideToken: "configAppBenef/fetchPideToken",
      saveTokenConfig: "configAppBenef/saveTokenConfig",
      setAlert: "user/setAlert"
    }),
    async loadParameters() {
      this.convenios = await this.fetchConvenios();
      this.convenios.unshift({ id: -1, value: "Todos" });
      this.origenes = await this.fetchOrigenes();
      this.origenes.unshift({ id: -1, value: "Todos" });
      this.optionsToken = await this.fetchPideToken();
    },
    async saveConfig() {
      const data = {
        TokenConfigId: this.tokenConfigId,
        OsId: this.convenioSelected === -1 ? null : this.convenioSelected,
        OriId: this.origenSelected === -1 ? null : this.origenSelected,
        PideTokenId: this.PideToken,
        VigenciaDesde: this.fechaDesde,
        VigenciaHasta: this.fechaHasta
      };
      const response = await this.saveTokenConfig(data);
      if (response.status === 200)
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      this.closeModal();
    },
    async setConfig() {
      const response = await this.getTokenConfigById(this.tokenConfigId);
      this.convenioSelected =
        this.isEdit == true && response.osId == null ? -1 : response.osId;
      this.origenSelected =
        this.isEdit == true && response.oriId == null ? -1 : response.oriId;
      this.PideToken = response.pideTokenId;
      this.fechaDesdeTextField = response.vigenciaDesde;
      this.fechaDesde = this.parseDateToIso(this.fechaDesdeTextField);
      this.fechaHastaTextField = response.vigenciaHasta;
      this.fechaHasta = this.parseDateToIso(this.fechaHastaTextField);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
